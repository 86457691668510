img {
  max-width: 100%;
  height: auto;
}

.scrollable-container {
  height: auto;
  overflow-y: auto;
  border: 1px solid #ccc;
  width: auto;
}

.custom-modal-dialog {
  background-color: #e9ecef;
}
